import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import {Footer, Heading, HorizontalDivider } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
// import HomeHero from "../sections/home/HomeHero";


const ThankYou = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='Thank You'
    >
      {/* <Announcement 
        containerBackgroundColor='var(--bf-core-teal)'
        textColor='white'
        // copy={data.homeAnnouncement.data.header}
      /> */}
      <HorizontalDivider />
      <Heading h2> banner </Heading>
      <HorizontalDivider />
      <Heading h2> how it works </Heading>
      <HorizontalDivider />
      <Heading h2> demographics </Heading>
      <HorizontalDivider />
      {/* <Heading h2> impact </Heading> */}
      {/* <HorizontalDivider /> */}
      <Heading h2> contact us </Heading>
      {/* <BasicNeedsLogos /> */}
      <Heading h2> you're in good company </Heading>
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//   query ThankYouPageContentQuery {
    
//   }
// `

export default ThankYou;